<template>
  <PageWithLayout
    bgColor="#F98E5A"
    :isBodyFull="true"
    :isCloseBtn="true"
    :isCloseWebEnd="true">
    <div class="wrap_error">
      <div class="head_error">
        <span class="tit_head">$orry..</span>
        <div class="area_img">
          <div class="inner_img"></div>
        </div>
      </div>
      <div class="body_error">
        <h1 class="tit_body">무슨 문제죠?!<br>일시적으로 접속할 수 없어요</h1>
        <p class="desc_body">문제가 계속되면 고객센터로 문의해 주세요.</p>
        <strong class="tit_sub">문제를 해결하려면</strong>
        <ul>
          <li>앱을 완전히 종료 후 다시 시작하기</li>
          <li>본인인증 로그인 다시 하기</li>
        </ul>
        <div class="group_btn">
          <Button
            btnSize="medium"
            btnStyle="secondary"
            text="이전으로"
            @onClickBtn="sendInterface('webError')" />

        </div>
      </div>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
import Button from '@/components/common/button/Button';
// mixins
import InterFaceMixin from '@/mixins/InterFaceMixin';
import BackKeyEventMixin from '@/mixins/BackKeyEventMixin';

export default {
  name: 'WebSessionError',
  mixins: [ InterFaceMixin, BackKeyEventMixin ],
  components:{
    PageWithLayout,
    Button
  },
  methods:{
    onClickBackKey(){
      this.webEnd();
    },
  }
}
</script>

<style scoped>
.wrap_error{min-height:100%;display:flex;flex-direction:column}
.head_error{display:flex;flex-direction:column;flex:1 1}
.head_error .tit_head{display:block;font-family:'Poppins';font-weight:700;font-size:28px;line-height:34px;color:#111;text-align:center}
.head_error .area_img{position:relative;flex:1 1;margin:24px 50px 42px}
.head_error .area_img .inner_img{position:absolute;top:50%;left:50%;width:100%;height:100%;max-width:260px;max-height:260px;background:url(/assets/images/img_error.png) no-repeat center;background-size:contain;transform: translate(-50%, -50%)}

.body_error{padding:24px 20px 32px;background-color:#fff}
.body_error .tit_body + .desc_body,
.body_error .desc_body + .tit_sub{margin-top:12px}
.body_error .tit_sub + ul{margin-top:4px}
.body_error .tit_body{font-weight:700;font-size:24px;line-height:30px;color:#111}
.body_error .desc_body{font-weight:500;font-size:14px;line-height:22px;color:#111}
.body_error .tit_sub{display:block;font-weight:700;font-size:16px;line-height:24px;color:#111}
.body_error ul li{padding-left:15px;font-weight:500;font-size:14px;line-height:22px;text-indent:-15px;color:#111}
.body_error ul li:before{display:inline-block;width:3px;height:3px;margin:10px 6px 9px;border-radius:100%;background-color:#333;vertical-align:top;content:''}
.body_error .group_btn{margin-top:20px}
</style>